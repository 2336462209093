import styled from '@emotion/styled';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Channel } from 'pusher-js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Scroll from 'react-scroll';
import { toast } from 'react-toastify';

import { CDN_URL, CocoButtonV2 } from '@coco/ui-web';

import { PAGE_LIMIT } from 'src/hooks/useDeliveriesHistory';
import { COMMON_KEYS, LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { useOrderStore } from 'src/stores/orders';
import { CanceledDeliveryEventData, ListDelivery, Mode, PUSHER_EVENTS } from '../../@types';
import { useDeliveries, useDeliveriesHistory } from '../../hooks';
import { getLateLoadedDeliveries, sortActiveDeliveries } from '../../lib/deliveries';
import { CocoError, FullPageLoader } from '..';
import { CanceledOrder } from '../Alert';
import DeliveryCard from './DeliveryCard';
import LateOrder from './LateOrder';
import WarningAudio from './WarningAudio';

interface Props {
  partnerIds: string[];
  mode: Mode;
  merchantChannel: Channel;
}

const Container = styled.div`
  padding: 0px 30px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div({
  width: '200px',
  alignSelf: 'center',
  marginBottom: '30px',
  marginTop: '10px',
});

const Deliveries = ({ partnerIds, mode, merchantChannel }: Props) => {
  const { t: commonT } = useTranslation(LOC_NS.COMMON);
  const { t: ordersT } = useTranslation(LOC_NS.ORDERS);
  const [openDeliveryId, setOpenDeliveryId] = useState<string>('');
  const { useMerchantDashboardNewFeatures, merchantPortalLoadReminders } = useFlags();

  const { canceledOrders, pushCanceledOrder } = useOrderStore();
  const toastId = useRef(null);

  const {
    isLoading: activeDeliveriesLoading,
    data: activeDeliveriesData,
    error: activeDeliveriesError,
    refetch,
  } = useDeliveries({
    params: {
      partnerIds: partnerIds,
    },
    config: {
      enabled: partnerIds.length > 0 && mode === Mode.ACTIVE,
    },
  });

  const {
    isLoading: historyDeliveriesLoading,
    isFetching: historyDeliveriesFetching,
    data: historyDeliveriesData,
    error: historyDeliveriesError,
    fetchNextPage,
  } = useDeliveriesHistory({
    params: {
      partnerIds: partnerIds,
    },
    enabled: partnerIds.length > 0 && mode === Mode.HISTORY,
  });

  const handleToggleOpen = useCallback((deliveryId: string) => {
    setOpenDeliveryId((currentOpenDeliveryId) => {
      if (currentOpenDeliveryId === deliveryId) {
        // toggle closed current delivery
        return '';
      }

      return deliveryId;
    });

    setTimeout(() => {
      Scroll.scroller.scrollTo(deliveryId, {
        smooth: true,
        duration: 300,
      });
    }, 300);
  }, []);

  useEffect(() => {
    if (merchantChannel) {
      merchantChannel.bind(PUSHER_EVENTS.CANCELED_DELIVERY, (data: CanceledDeliveryEventData) => {
        console.log('new canceled order!!', data);

        if (!data || !data.deliveryId || !data.dropoffName) {
          console.log('ignoring malformed pusher data');
          return;
        }

        const canceledDelivery = (activeDeliveriesData as ListDelivery[]).find(
          (delivery) => delivery.id === data.deliveryId
        );

        if (canceledDelivery?.statusTimestampsMap?.InTransit) {
          console.log('skipping alerting since delivery is already in transit', canceledDelivery);
          return;
        }

        pushCanceledOrder(data);

        refetch();
      });
    }

    return () => {
      if (merchantChannel) {
        merchantChannel.unbind(PUSHER_EVENTS.CANCELED_DELIVERY);
      }
    };
  }, [activeDeliveriesData, merchantChannel, pushCanceledOrder, refetch]);

  const canceledOrderAutoCloseMs = useMemo(() => {
    if (merchantPortalLoadReminders?.orderCancelationDisplayDurationMins) {
      return merchantPortalLoadReminders.orderCancelationDisplayDurationMins * 60 * 1000;
    }
    return 10000;
  }, [merchantPortalLoadReminders]);

  useEffect(() => {
    if (canceledOrders.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!toast.isActive('canceled-order')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toastId.current = toast(
          <CanceledOrder
            repeatAudioCount={merchantPortalLoadReminders?.orderCancelationRepeatAudioCount}
            repeatAudioDelay={merchantPortalLoadReminders?.orderCancelationRepeatAudioDelayMs}
          />,
          {
            toastId: 'canceled-order',
            autoClose: canceledOrderAutoCloseMs,
          }
        );
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toast.update(toastId.current, {
          autoClose: canceledOrderAutoCloseMs, // reset autoclose timer when a new canceled order comes through
        });
      }
    }
  }, [canceledOrders.length, canceledOrderAutoCloseMs, merchantPortalLoadReminders]);

  const experimentActive = useMemo(() => merchantPortalLoadReminders?.active, [merchantPortalLoadReminders?.active]);

  const lateLoadedDeliveries = useMemo(() => {
    return getLateLoadedDeliveries(activeDeliveriesData);
  }, [activeDeliveriesData]);

  const isLoading = mode === Mode.ACTIVE ? activeDeliveriesLoading : historyDeliveriesLoading;
  const error = mode === Mode.ACTIVE ? activeDeliveriesError : historyDeliveriesError;

  const allHistoricalDeliveries = useMemo(() => {
    let acc: ListDelivery[] = [];
    historyDeliveriesData?.pages.forEach((page) => {
      acc = [...acc, ...page.data.deliveries];
    });
    return acc;
  }, [historyDeliveriesData?.pages]);

  const sortedActiveDeliveries = useMemo(() => {
    if (mode === Mode.ACTIVE) {
      return sortActiveDeliveries(activeDeliveriesData, merchantPortalLoadReminders?.maxCanceledAgeMins);
    }
    return allHistoricalDeliveries;
  }, [activeDeliveriesData, allHistoricalDeliveries, merchantPortalLoadReminders?.maxCanceledAgeMins, mode]);

  const allowLoadMore = useMemo(() => {
    return (
      mode === Mode.HISTORY &&
      historyDeliveriesData &&
      historyDeliveriesData.pages[historyDeliveriesData.pages.length - 1].data.deliveries.length === PAGE_LIMIT
    );
  }, [mode, historyDeliveriesData]);

  if (isLoading || !merchantPortalLoadReminders) {
    return <FullPageLoader />;
  }

  if (error) {
    return (
      <CocoError
        errorMessage={ordersT(ORDERS_KEYS.UNABLE_TO_FETCH_ORDERS, 'Unable to fetch orders - please try again later')}
        assetUrl={`${CDN_URL}/images/coco-error.png`}
        showRefresh
      />
    );
  }

  if (sortedActiveDeliveries?.length === 0) {
    return (
      <CocoError
        errorMessage={ordersT(ORDERS_KEYS.NO_ACTIVE_ORDERS, 'No active orders')}
        assetUrl={`${CDN_URL}/images/coco.png`}
      />
    );
  }

  return (
    <Container>
      {sortedActiveDeliveries?.map((delivery) => {
        return (
          <div key={delivery.id}>
            <Scroll.Element name={delivery.id} className="element">
              <DeliveryCard
                delivery={delivery}
                open={delivery.id === openDeliveryId}
                onToggle={() => handleToggleOpen(delivery.id)}
                showPartner={partnerIds.length > 1}
                isActiveOrdersTab={mode === Mode.ACTIVE}
                useMerchantDashboardNewFeatures={useMerchantDashboardNewFeatures}
              />
            </Scroll.Element>
          </div>
        );
      })}
      {allowLoadMore && (
        <ButtonContainer>
          <CocoButtonV2
            onClick={() => {
              fetchNextPage();
            }}
            size="large"
            buttonType="primary"
            status={historyDeliveriesFetching ? 'loading' : 'enabled'}
            flexible
            hideShadows
          >
            {commonT(COMMON_KEYS.LOAD_MORE, 'Load More')}
          </CocoButtonV2>
        </ButtonContainer>
      )}
      {/* Either show just warning audio or warning audio + popup depending on launchdarkly flag */}
      {lateLoadedDeliveries.length > 0 &&
        mode === Mode.ACTIVE &&
        (experimentActive ? (
          <LateOrder experiment={merchantPortalLoadReminders} lateLoadedDeliveries={lateLoadedDeliveries} />
        ) : (
          <WarningAudio />
        ))}
    </Container>
  );
};

export default Deliveries;
