import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CDN_URL, Colors, Typography } from '@coco/ui-web';

import { EVENTS } from 'src/@types';
import { COMMON_KEYS, LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { trackSegment } from 'src/lib/segment';
import { delayAsync } from 'src/lib/utils';
import { useOrderStore } from 'src/stores/orders';

interface Props {
  closeToast?: () => void; // injected by react-toastify
  repeatAudioCount?: number;
  repeatAudioDelay?: number;
}

const Container = styled.div({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: Colors.grey300,
});

const Body = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Title = styled.div({
  color: Colors.grey900,
  marginBottom: '20px',
});

const CircleContainer = styled.div({
  width: '360px',
  height: '360px',
  borderRadius: '50%',
  backgroundColor: Colors.white,
  marginBottom: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-height: 675px)': {
    width: '240px',
    height: '240px',
  },
  '@media (max-width: 540px)': {
    width: '300px',
    height: '300px',
  },
});

const Count = styled.div({
  color: Colors.grey900,
  marginBottom: '24px',
});

const OrderCopy = styled.div({
  color: Colors.grey900,
});

const Caption = styled.div({
  color: Colors.grey900,
  marginBottom: '40px',
});

const TapAnywhere = styled.div({
  color: Colors.grey900,
});

export const CanceledOrder = ({ closeToast, repeatAudioCount = 1, repeatAudioDelay = 12000 }: Props) => {
  const { t: commonT } = useTranslation(LOC_NS.COMMON);
  const { t: ordersT } = useTranslation(LOC_NS.ORDERS);
  const { canceledOrders, setCanceledOrders } = useOrderStore();

  const { user } = useAuth0();

  const [audio] = useState(
    typeof Audio !== 'undefined' ? new Audio(`${CDN_URL}/audio/reminder.mp3`) : ({} as typeof Audio)
  );

  const count = canceledOrders.length;
  const orderIds = useMemo(() => canceledOrders.map((name) => name), [canceledOrders]);

  const handleClose = useCallback(async () => {
    if (closeToast) {
      // What the merchant dismisses the Order Cancellation alert pop-up
      trackSegment(EVENTS.BUTTON_CLICKED, {
        order_count: count,
        button_name: 'Manual Dismiss - Order Cancellation Alert Screen',
        order_ids: orderIds,
        auth_id: user?.sub,
        button_clicked_at: Date.now(),
      });

      audio?.pause();
      closeToast();
    }
    await delayAsync(1000);
    setCanceledOrders([]);
  }, [audio, closeToast, count, orderIds, setCanceledOrders, user?.sub]);

  useEffect(() => {
    let count = repeatAudioCount;
    let timer: NodeJS.Timeout;
    const run = () => {
      if (count > 0) {
        audio?.play();
        count -= 1;
        timer = setTimeout(run, repeatAudioDelay);
      }
    };

    run();

    return () => clearTimeout(timer);
  }, [audio, repeatAudioDelay, repeatAudioCount]);

  useEffect(() => {
    // When the merchant sees the Order Cancellation alert popup
    trackSegment(EVENTS.PAGE_VIEWED, {
      order_count: count,
      order_ids: orderIds,
      page_name: 'Order Cancellation Alert Screen',
      auth_id: user?.sub,
      page_viewed_at: Date.now(),
    });
  }, [count, orderIds, user?.sub]);

  const uniqueNames = useMemo(() => {
    const set = new Set<string>();
    canceledOrders.forEach(({ dropoffName }) => {
      set.add(dropoffName);
    });

    const arr = Array.from(set);

    const trimmedUniqueNames = arr.map((name) => {
      return name.length > 15 ? `${name.slice(0, 15)}...` : name;
    });

    return trimmedUniqueNames as string[];
  }, [canceledOrders]);

  return (
    <Container onClick={handleClose}>
      <Body>
        <Title className={Typography.HEADLINE_LG}>
          {ordersT(ORDERS_KEYS.RECEIVED_CANCELATION_FOR, 'Received Cancelation for')}
        </Title>

        <CircleContainer>
          <Count className={Typography.DISPLAY_LG}>{canceledOrders.length}</Count>
          <OrderCopy className={Typography.DISPLAY_MD}>
            {count === 1 ? ordersT(ORDERS_KEYS.ORDER, 'Order') : ordersT(ORDERS_KEYS.ORDERS, 'Orders')}
          </OrderCopy>
        </CircleContainer>

        <Caption className={Typography.HEADLINE_MD}>{uniqueNames.length < 5 ? uniqueNames.join(' | ') : ''}</Caption>

        <TapAnywhere className={Typography.HEADLINE_SM}>
          {commonT(COMMON_KEYS.TAP_ANYWHERE_TO_CLOSE, 'Tap anywhere to close')}
        </TapAnywhere>
      </Body>
    </Container>
  );
};
