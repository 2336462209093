import { datadogLogs } from '@datadog/browser-logs';
import { FullStory } from '@fullstory/browser';
import { AxiosError } from 'axios';

import { ERROR, EVENTS } from 'src/@types';

export const logError = async (err: unknown, event: ERROR, context = {}): Promise<void> => {
  const error =
    (err as AxiosError<{ message: string }>)?.response?.data?.message ??
    (err as AxiosError<{ message: string }>)?.message ??
    '';

  FullStory('trackEvent', {
    name: event,
    properties: {
      error,
      ...context,
    },
  });

  const fullstorySession = await getFullStorySessionURLNow();
  datadogLogs.logger.error(event, {
    error,
    ...context,
    fullstorySession,
  });
};

export const logEvent = async (event: EVENTS, context = {}): Promise<void> => {
  const fullstorySession = await getFullStorySessionURLNow();
  datadogLogs.logger.info(event, {
    ...context,
    fullstorySession,
  });
};

export const getFullStorySessionURLNow = async () => {
  let url;
  try {
    url = await FullStory('getSessionAsync', { format: 'url.now' });
  } catch (err) {
    console.error('Error getting FullStory session URL', err);
  }

  return url;
};
