import { Coordinate } from './map';

export enum TripType {
  JITP = 'JITP',
  DELIVERY = 'DELIVERY',
}

export enum TripStatus {
  AT_STOP = 'AT_STOP',
  READY_TO_DEPART = 'READY_TO_DEPART',
  IN_TRANSIT = 'IN_TRANSIT',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  AT_DESTINATION = 'AT_DESTINATION',
  SCHEDULED = 'SCHEDULED',
  PENDING = 'PENDING',
}

export interface Trip {
  id: string;
  type: TripType;
  routeCoordinates: Coordinate[];
  status: TripStatus;
  createdAt: string;
  eta: number;
  distanceMiles: number;
}
