import { create } from 'zustand';

import { CanceledDeliveryEventData } from 'src/@types';

export interface PilotState {
  canceledOrders: CanceledDeliveryEventData[];
  setCanceledOrders: (canceledOrders: PilotState['canceledOrders']) => void;
  pushCanceledOrder: (canceledOrder: PilotState['canceledOrders'][0]) => void;
  selfCanceledDeliveryId: string | null;
  setSelfCanceledDeliveryId: (deliveryId: PilotState['selfCanceledDeliveryId']) => void;
}

const initialState = {
  canceledOrders: [],
  selfCanceledDeliveryId: null,
};

export const useOrderStore = create<PilotState>((set, get) => ({
  ...initialState,
  pushCanceledOrder: (eventData: CanceledDeliveryEventData) => {
    const { canceledOrders, selfCanceledDeliveryId } = get();

    if (canceledOrders.find((order) => order.dropoffName === eventData.dropoffName)) {
      console.log('dupe canceled order');
      return;
    }

    let resetSelfCanceledDeliveryId = false;
    if (eventData.deliveryId === selfCanceledDeliveryId) {
      // handle edge case where merchant cancels a delivery from tablet - they don't need to receive the order canceled alert
      console.log('dont show alert since delivery was self-canceled');
      resetSelfCanceledDeliveryId = true;
      return;
    }

    set((state) => ({
      ...state,
      selfCanceledDeliveryId: resetSelfCanceledDeliveryId ? null : selfCanceledDeliveryId,
      canceledOrders: [...canceledOrders, eventData],
    }));
  },

  setCanceledOrders: (canceledOrders) =>
    set((state) => ({
      ...state,
      canceledOrders,
    })),

  setSelfCanceledDeliveryId: (deliveryId) =>
    set((state) => ({
      ...state,
      selfCanceledDeliveryId: deliveryId,
    })),
}));
