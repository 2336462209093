import '@coco/ui-web/src/css/coco-styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'src/css/ToastOverrides.css';
import 'src/i18n/init';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { init as initFullStory } from '@fullstory/browser';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import { ComponentType } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

import { ERROR } from 'src/@types';
import { logError } from 'src/lib/logging';
import { AccessProvider, AppContext, Header } from '../components';
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_ORIGIN_URL,
  FULLSTORY_ORG_ID,
  LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
  NEXT_PUBLIC_APP_VERSION,
  NEXT_PUBLIC_AWS_ENV,
  NODE_ENV,
} from '../lib/env';

if (typeof window !== 'undefined') {
  initFullStory({ orgId: FULLSTORY_ORG_ID, devMode: NODE_ENV === 'development' });
}

const ENABLE_DD = ['staging', 'beta', 'prod'].includes(NEXT_PUBLIC_AWS_ENV);

datadogLogs.init({
  clientToken: 'pub1245c56d16cd8a7d1cac61b223bdf6de',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  forwardConsoleLogs: ['warn', 'error'],
  sessionSampleRate: 100,
  service: 'merchant',
  env: NEXT_PUBLIC_AWS_ENV,
  version: NEXT_PUBLIC_APP_VERSION,
});

datadogRum.init({
  applicationId: '3ec3e2c7-ba1d-4933-b69c-3bd330c25822',
  clientToken: 'pub1245c56d16cd8a7d1cac61b223bdf6de',
  site: 'datadoghq.com',
  service: 'merchant',
  env: NEXT_PUBLIC_AWS_ENV,
  version: NEXT_PUBLIC_APP_VERSION,
  sessionSampleRate: ENABLE_DD ? 100 : 0,
  sessionReplaySampleRate: ENABLE_DD ? 20 : 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    /https:\/\/(deliveries|operations|device|state|routing|storefront|integrations).*\.cocodelivery\.com/,
  ],
  traceSampleRate: ENABLE_DD ? 100 : 0,
});

/*
 * https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#3-protecting-a-route-in-a-nextjs-app-in-spa-mode
 * */
const handleRedirectCallback = (appState: AppState | undefined): void => {
  Router.replace(appState?.returnTo || '/');
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      logError(error, ERROR.QUERY_FN_ERROR, {
        queryKey: query.queryKey,
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, context) => {
      logError(error, ERROR.MUTATION_FN_ERROR, {
        variables,
        context,
      });
    },
  }),
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Coco | Merchant</title> {/* https://nextjs.org/docs/messages/no-document-title */}
      </Head>

      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        redirectUri={AUTH0_ORIGIN_URL}
        cacheLocation="localstorage"
        onRedirectCallback={handleRedirectCallback}
        useRefreshTokens
      >
        <QueryClientProvider client={queryClient}>
          <AccessProvider>
            <AppContext.Provider>
              <Header />
              <ToastContainer
                position="top-center"
                closeOnClick={false}
                draggable={false}
                autoClose={false}
                closeButton={false}
                pauseOnHover={false}
                pauseOnFocusLoss={false}
                progressStyle={{
                  height: '10px',
                  background: 'var(--toastify-color-progress-dark)',
                }}
                limit={1}
              />
              <Component {...pageProps} />
            </AppContext.Provider>
          </AccessProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </>
  );
}

export default withLDProvider({
  clientSideID: LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
})(MyApp as ComponentType<object>); // https://launchdarkly.com/blog/using-launchdarkly-with-typescript/
