import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import Button from './shared/Button';

interface Props {
  errorMessage: string;
  assetUrl: string;
  showRefresh?: boolean;
}

const Container = styled.div`
  padding: 0px 30px;
  height: calc(100vh - 82px - 100px); // 82px is height of header and 100px is height of mode selector
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 600;
  text-align: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CocoErrorImg = styled.img`
  height: 400px;
  margin-bottom: 50px;
  @media (max-height: 700px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 50px;
  width: 300px;
`;

const CocoError = ({ errorMessage, assetUrl, showRefresh = false }: Props) => {
  const { t } = useTranslation(LOC_NS.ORDERS);

  const refreshPage = useCallback(() => {
    return window?.location.reload();
  }, []);

  return (
    <Container>
      <ErrorContainer>
        <CocoErrorImg src={assetUrl} />
        <Title>{errorMessage}</Title>
        {showRefresh && (
          <ButtonContainer>
            <Button onClick={refreshPage}>{t(ORDERS_KEYS.REFRESH_ORDERS, 'Refresh orders')}</Button>
          </ButtonContainer>
        )}
      </ErrorContainer>
    </Container>
  );
};

export default CocoError;
