export enum UserRole {
  Admin = 'Admin',
  Dispatch = 'Dispatch',
  Pilots = 'Pilots',
  Support = 'Support',
  OpsAdmin = 'OpsAdmin',
  Engineering = 'Engineering',
  FieldOps = 'FieldOps',
  Merchants = 'Merchants',
  OpsBetaTester = 'OpsBetaTester',
}
