import { AxiosInstance } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { Delivery } from '@coco/types/deliveries';

import { AppContext } from '../components';

interface Params {
  deliveryId: string;
  config?: UseQueryOptions<Delivery>;
}

const fetchDeliveryV3 = async (deliveriesApi: AxiosInstance, deliveryId: string): Promise<Delivery> => {
  const { data } = await deliveriesApi.get(`/api/v3/delivery/${deliveryId}`);

  return data;
};

export default function useDeliveryV3({ deliveryId, config }: Params) {
  const { deliveriesApi } = AppContext.useAppContext();

  return useQuery({
    queryKey: ['delivery-v3', deliveryId],
    queryFn: () => fetchDeliveryV3(deliveriesApi, deliveryId),
    ...config,
  });
}
