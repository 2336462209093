import { add, differenceInMinutes, intervalToDuration, isFuture, isPast } from 'date-fns';

import { AttemptStatus } from '@coco/types/deliveries';

import { DELIVERY_STATUS_KEYS } from 'src/i18n/types';
import { DeliveryOperationalMode, ListDelivery, Status, StatusType } from '../@types';
import { calculateTimeCopy, deliveryStatusT, durationToTimeCopy } from './delivery-shared';

const calculateDDPickupCopy = (eta: Date) => {
  if (isPast(eta)) {
    return deliveryStatusT(DELIVERY_STATUS_KEYS.ARRIVING_SOON, 'Arriving soon');
  }

  const duration = intervalToDuration({
    start: new Date(),
    end: eta,
  });

  return `${durationToTimeCopy(duration)} ${deliveryStatusT(DELIVERY_STATUS_KEYS.TO_PICKUP, 'to pickup')}`;
};

const calculateDDDropoffCopy = (eta: Date) => {
  const etaPlus5 = add(eta, {
    minutes: 5,
  });

  if (isFuture(new Date(etaPlus5))) {
    const diffInMinutes = differenceInMinutes(etaPlus5, new Date());
    if (diffInMinutes === 0) {
      return deliveryStatusT(DELIVERY_STATUS_KEYS.ARRIVING_SOON, 'Arriving soon');
    }
  }

  if (isPast(etaPlus5)) {
    return deliveryStatusT(DELIVERY_STATUS_KEYS.ARRIVING_SOON, 'Arriving soon');
  }

  const duration = intervalToDuration({
    start: new Date(),
    end: etaPlus5,
  });

  return `${durationToTimeCopy(duration)} ${deliveryStatusT(DELIVERY_STATUS_KEYS.TO_DEST, 'to dest')}`;
};

const calculateDDPickupEta = (deliveryMedium: ListDelivery['deliveryMedium'], eta: string): Date => {
  if (deliveryMedium?.pickupTime && deliveryMedium.pickupTime.length > 0) {
    return new Date(deliveryMedium.pickupTime);
  }
  return new Date(eta);
};

const calculateDDDeliveryEta = (deliveryMedium: ListDelivery['deliveryMedium'], eta: string): Date => {
  if (deliveryMedium?.dropoffTime && deliveryMedium.dropoffTime.length > 0) {
    return new Date(deliveryMedium.dropoffTime);
  }
  return new Date(eta);
};

export const getDoorDashStatus = (
  currentAttemptStatus: ListDelivery['currentAttemptStatus'],
  statusTimestampsMap: ListDelivery['statusTimestampsMap'],
  deliveryMedium: ListDelivery['deliveryMedium'],
  eta: ListDelivery['eta'],
  operationalMode: ListDelivery['operationalMode'],
  updatedAt: ListDelivery['updatedAt']
): Status => {
  if (
    statusTimestampsMap?.Requested &&
    (currentAttemptStatus === AttemptStatus.Pending ||
      currentAttemptStatus === AttemptStatus.Requested ||
      currentAttemptStatus === AttemptStatus.Scheduled ||
      currentAttemptStatus === AttemptStatus.RequestFailed)
  ) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.WAITING_FOR_DRIVER, 'Waiting for driver'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.Requested))}`,
      statusType: StatusType.ON_THE_WAY_TO_PICKUP,
    };
  }

  /* Order canceled (but attempt status has not updated yet) */
  if (operationalMode === DeliveryOperationalMode.Canceled && updatedAt) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.CANCELED, 'Canceled'),
      timeCopy: `${calculateTimeCopy(new Date(updatedAt))}`,
      statusType: StatusType.CANCELED,
    };
  }

  if (statusTimestampsMap?.Assigned && currentAttemptStatus === AttemptStatus.Assigned) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.ON_THE_WAY_TO_PICKUP, 'On the way to pickup'),
      timeCopy: calculateDDPickupCopy(calculateDDPickupEta(deliveryMedium, eta)),
      statusType: StatusType.ON_THE_WAY_TO_PICKUP,
    };
  }

  if (statusTimestampsMap?.AtPickup && currentAttemptStatus === AttemptStatus.AtPickup) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.DRIVER_WAITING, 'Driver waiting'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.AtPickup))}`,
      statusType: StatusType.ON_THE_WAY_TO_PICKUP,
    };
  }

  if (currentAttemptStatus === AttemptStatus.InTransit) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.ON_THE_WAY_TO_CUSTOMER, 'On the way to customer'),
      timeCopy: calculateDDDropoffCopy(calculateDDDeliveryEta(deliveryMedium, eta)),
      statusType: StatusType.IN_TRANSIT,
    };
  }

  if (statusTimestampsMap?.AtDestination && currentAttemptStatus === AttemptStatus.AtDestination) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.AT_CUSTOMER, 'At customer'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.AtDestination))}`,
      statusType: StatusType.AT_CUSTOMER,
    };
  }

  if (statusTimestampsMap?.AtDestination && currentAttemptStatus === AttemptStatus.Delivered) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.DELIVERED, 'Delivered'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.AtDestination))}`,
      statusType: StatusType.DELIVERED,
    };
  }

  /* Order canceled */
  if (statusTimestampsMap?.Canceled && currentAttemptStatus === AttemptStatus.Canceled) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.CANCELED, 'Canceled'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.Canceled))}`,
      statusType: StatusType.CANCELED,
    };
  }

  return {
    statusCopy: `${currentAttemptStatus}`,
    timeCopy: '',
    statusType: StatusType.ON_THE_WAY_TO_PICKUP,
  };
};
