import { formatDuration, intervalToDuration, isBefore, startOfDay } from 'date-fns';
import { t } from 'i18next';

import { COMMON_KEYS, DELIVERY_STATUS_KEYS, LOC_NS } from 'src/i18n/types';
import { getDateFnsLocale, localeFormatDate } from './utils';

export const deliveryStatusT = (key: DELIVERY_STATUS_KEYS, fallback: string) => {
  return t(`${LOC_NS.DELIVERY_STATUS}:${key}`, fallback);
};

export const commonT = (key: COMMON_KEYS, fallback: string) => {
  return t(`${LOC_NS.COMMON}:${key}`, fallback);
};

export const calculateTimeCopy = (time: Date) => {
  if (isBefore(time, startOfDay(new Date()))) {
    // if date took place before current day, just show the date
    return localeFormatDate(time, 'MM-dd-yyyy');
  }

  let start: Date = new Date();
  let end: Date = new Date();
  let isAfter = false;

  if (isBefore(time, new Date())) {
    start = time;
    end = new Date();
  } else {
    start = new Date();
    end = time;
    isAfter = true;
  }

  const duration = intervalToDuration({
    start,
    end,
  });

  let durationCopy = durationToTimeCopy(duration);
  if (!isAfter) {
    durationCopy = `${durationCopy} ${commonT(COMMON_KEYS.AGO, 'ago')}`;
  }

  return durationCopy;
};

export const durationToTimeCopy = (duration: Duration) => {
  const locale = getDateFnsLocale();
  const formattedDuration = formatDuration(duration, { locale, format: ['hours', 'minutes'] });

  return formattedDuration !== '' ? formattedDuration : commonT(COMMON_KEYS.LESS_THAN_ONE_MIN, '< 1 minute');
};
