import { useAuth0 } from '@auth0/auth0-react';
import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';

import { Partner } from '../@types';
import { AppContext } from '../components';

const getMerchantPartners = async (deliveriesApi: AxiosInstance, userId: string): Promise<Partner[]> => {
  const { data } = await deliveriesApi.get(`/api/v2/delivery/partners/${userId}`);

  return data;
};

const usePartners = () => {
  const { deliveriesApi } = AppContext.useAppContext();
  const { user } = useAuth0();

  return useQuery({
    queryKey: 'merchant-partners',
    queryFn: () => getMerchantPartners(deliveriesApi, user?.sub ?? ''),
  });
};

export default usePartners;
