/* 1230 -> $12.30 */
export const formatCentsToDollars = (cents: number | undefined): string => {
  if (cents !== 0 && !cents) {
    // handle null and undefined cases
    return '';
  }

  const dollarValue = +cents / 100;

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dollarValue);
};
