import { AxiosInstance } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { Trip } from '../@types';
import { AppContext } from '../components';

interface Params {
  attemptId: string;
  config?: UseQueryOptions;
}

const fetchTrips = async (operationsApi: AxiosInstance, attemptId: string): Promise<Trip[]> => {
  const { data } = await operationsApi.get(`/beta/trips`, { params: { attemptId } });
  return data;
};

const useTrips = ({ config, attemptId }: Params) => {
  const { operationsApi } = AppContext.useAppContext();
  return useQuery({
    queryKey: ['trips', attemptId],
    queryFn: () => fetchTrips(operationsApi, attemptId),
    ...config,
  });
};

export default useTrips;
