import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON_KEYS, LOC_NS } from 'src/i18n/types';
import { AUTH0_ORIGIN_URL } from '../lib/env';
import Button from './shared/Button';

const FullPageContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Header = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
`;

const Copy = styled.div`
  font-size: 16px;
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  width: 250px;
`;

const Unauthorized = () => {
  const { t } = useTranslation(LOC_NS.COMMON);
  const { logout } = useAuth0();

  const handleLogout = useCallback(() => {
    logout({
      returnTo: AUTH0_ORIGIN_URL,
    });
  }, [logout]);

  return (
    <FullPageContainer>
      <Header>{t(COMMON_KEYS.UNAUTHORIZED, 'Unauthorized')}</Header>
      <Copy>{t(COMMON_KEYS.MISSING_PERMISSIONS, 'You do not have the required permissions to view this page.')}</Copy>

      <ButtonContainer>
        <Button onClick={handleLogout}>{t(COMMON_KEYS.LOGOUT, 'Logout')}</Button>
      </ButtonContainer>
    </FullPageContainer>
  );
};

export default Unauthorized;
