import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import mapboxgl, { LngLatLike } from 'mapbox-gl';

import {
  Bot as BotIcon,
  CourierImg as CourierImg,
  Customer as CustomerIcon,
  DeliveryRescueImg,
  Driver as DriverIcon,
  GreenBot as GreenBotIcon,
  Merchant as MerchantIcon,
  One as OneIcon,
  RedBot as RedBotIcon,
  Two as TwoIcon,
  YellowBot as YellowBotIcon,
} from '../assets';

export enum MapBoxResourceIds {
  DRIVER = 'driver',
  BOT = 'bot',
  BOT2 = 'bot2',
  MERCHANT = 'merchant',
  CUSTOMER = 'customer',
  TRAYECTORY = 'trayectory',
  UNCLUSTERED_ROBOT_POINT = 'unclustered_robot_point',
  ROBOT_CLUSTERS = 'robots_clusters',
  ROUTE = 'route',
  ROUTE_ROBOT = 'route_robot',
  ENROUTE_ROBOT = 'enroute_robot',
  FIELD_OP = 'field_op',
  OLO_DRIVER = 'OLO_DRIVER',
  TASK_LOCATION_1 = 'task_location_1',
  TASK_LOCATION_2 = 'task_location_2',
}

export enum MapIds {
  DeliveryTripMap = 'DeliveryTripMap',
  ReturnTripMap = 'ReturnTripMap',
  FieldOpsMap = 'FieldOpsMap',
  DeliveriesMap = 'DeliveriesMap',
  FleetMap = 'FleetMap',
}

export enum MapBoxIconNames {
  DRIVER = 'driver',
  OLO_DRIVER = 'olo_driver',
  BOT = 'bot',
  BOT2 = 'bot2',
  MERCHANT = 'merchant',
  CUSTOMER = 'customer',
  DELIVERY_RESCUE = 'delivery_rescue',
  GREEN_BOT = 'green_bot',
  YELLOW_BOT = 'yellow_bot',
  RED_BOT = 'red_bot',
  TASK_LOCATION_1 = 'task_location_1',
  TASK_LOCATION_2 = 'task_location_2',
}

export const MAPBOX_ICONS = {
  [MapBoxIconNames.DRIVER]: DriverIcon.src,
  [MapBoxIconNames.OLO_DRIVER]: CourierImg.src,
  [MapBoxIconNames.BOT]: BotIcon.src,
  [MapBoxIconNames.MERCHANT]: MerchantIcon.src,
  [MapBoxIconNames.CUSTOMER]: CustomerIcon.src,
  [MapBoxIconNames.DELIVERY_RESCUE]: DeliveryRescueImg.src,
  [MapBoxIconNames.GREEN_BOT]: GreenBotIcon.src,
  [MapBoxIconNames.YELLOW_BOT]: YellowBotIcon.src,
  [MapBoxIconNames.RED_BOT]: RedBotIcon.src,
  [MapBoxIconNames.TASK_LOCATION_1]: OneIcon.src,
  [MapBoxIconNames.TASK_LOCATION_2]: TwoIcon.src,
};

export type Coordinate = [number, number];

export interface CleanResourcesByIdParams {
  id: MapBoxResourceIds | string;
  map?: mapboxgl.Map;
}

export interface NewLineFeatureParams {
  coordinates: Coordinate[];
}

export interface NewPointFeatureParams {
  coordinates: Coordinate;
  title?: string;
  properties?: object;
}

export interface RenderIconParams {
  map: mapboxgl.Map;
  icon: MapBoxIconNames;
  id: MapBoxResourceIds | string;
  data: Feature<Geometry, GeoJsonProperties>;
}

export interface AddSourceParams {
  id: MapBoxResourceIds | string;
  type: 'geojson';
  data: Feature<Geometry, GeoJsonProperties>;
  map: mapboxgl.Map;
}

export interface RenderRouteParams {
  map: mapboxgl.Map;
  id: MapBoxResourceIds;
  data: Feature<Geometry, GeoJsonProperties>;
  color: 'white';
}

export interface AddLayerParams {
  id: MapBoxResourceIds | string;
  icon: string;
  map: mapboxgl.Map;
}

export enum SupportedMapboxStyles {
  DARK = 'mapbox://styles/mapbox/dark-v10?optimize=true',
  LIGHT = 'mapbox://styles/mapbox/light-v10?optimize=true',
  STREETS = 'mapbox://styles/mapbox/streets-v11?optimize=true',
  OUTDOORS = 'mapbox://styles/mapbox/outdoors-v11?optimize=true',
  SATELLITE = 'mapbox://styles/mapbox/satellite-v9?optimize=true',
}

export const MAPBOX_DEFAULT_OPTIONS = {
  style: 'mapbox://styles/mapbox/dark-v10?optimize=true',
  center: [-118.244, 34.052] as LngLatLike,
  zoom: 9,
  attributionControl: false,
};

export interface LatLng {
  lat: number;
  lng: number;
}
