import { create } from 'zustand';

import { ListDelivery } from 'src/@types';

export interface DeliveryState {
  selectedDelivery: ListDelivery | null;
  setSelectedDelivery: (selectedDelivery: DeliveryState['selectedDelivery']) => void;
}

const initialState = {
  selectedDelivery: null,
};

export const useDeliveryStore = create<DeliveryState>((set) => ({
  ...initialState,
  setSelectedDelivery: (selectedDelivery) =>
    set((state) => ({
      ...state,
      selectedDelivery,
    })),
}));
