import { css } from '@emotion/react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { CDN_URL } from '@coco/ui-web';

import { DELIVERY_STATUS_KEYS, LOC_NS } from 'src/i18n/types';
import { localeFormatDate } from 'src/lib/utils';

interface Props {
  timeRequested: string;
  timeLoaded: string;
  timeAtCustomer: string;
  timeDelivered: string;
  isRobotOrder: boolean;
}

const classes = {
  containerHorizontal: css({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '40px',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  bar: css({
    position: 'relative',
    width: '120px',
    marginLeft: '-10px',
    marginRight: '-10px',
    '@media (max-width: 600px)': {
      display: 'none',
    },
  }),
  barVertical: css({
    position: 'relative',
    height: '40px',
    width: '10px',
    marginTop: '-10px',
    marginBottom: '-10px',
    '@media (min-width: 601px)': {
      display: 'none',
    },
  }),
  step: css({
    height: '110px',
    display: 'flex',
    flexDirection: 'column',
    width: '150px',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  stateLabel: css({
    fontWeight: '500',
    position: 'absolute',
    top: '0px',
    textAlign: 'center',
    paddingTop: '7px',
    color: '#636365', // grey700
    fontSize: '14px',
    width: '100px',
    '@media (max-width: 600px)': {
      position: 'relative',
      paddingTop: '0px',
      width: '100px',
      textAlign: 'right',
    },
  }),
  captionLabel: css({
    position: 'absolute',
    fontWeight: '500',
    bottom: '0px',
    width: '100px',
    fontSize: '14px',
    color: '#636365', // grey700
    textAlign: 'center',
    '@media (max-width: 600px)': {
      position: 'relative',
      width: '100px',
      textAlign: 'left',
    },
  }),
  stepContainer: css({
    position: 'relative',
    height: '125px',
    width: '50x',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      height: '50px',
      width: '270px',
      justifyContent: 'space-between',
    },
  }),
  iconContainer: css({
    position: 'relative',
    height: '50px',
    width: '50px',
    zIndex: '1',
  }),
};

const ProgressBar = ({ timeRequested, timeLoaded, timeAtCustomer, timeDelivered, isRobotOrder }: Props) => {
  const { t } = useTranslation(LOC_NS.DELIVERY_STATUS);

  return (
    <div css={classes.containerHorizontal}>
      <div css={classes.stepContainer}>
        <div css={classes.stateLabel}>{t(DELIVERY_STATUS_KEYS.RECEIVED, 'Received')}</div>
        <div css={classes.iconContainer}>
          <Image alt="Merchant" src={`${CDN_URL}/images/icons/progress-icon-merchant.svg`} layout="fill" />
        </div>

        <div css={classes.captionLabel}>{localeFormatDate(new Date(timeRequested), 'p')}</div>
      </div>

      <div css={classes.bar}>
        <Image alt="" src={`${CDN_URL}/images/gradient-bar.svg`} layout="fill" />
      </div>

      <div css={classes.barVertical}>
        <Image alt="" src={`${CDN_URL}/images/gradient-bar-vertical-2.svg`} layout="fill" />
      </div>

      <div css={classes.stepContainer}>
        <div css={classes.stateLabel}>
          {isRobotOrder ? t(DELIVERY_STATUS_KEYS.LOADED, 'Loaded') : t(DELIVERY_STATUS_KEYS.PICKED_UP, 'Picked Up')}
        </div>
        <div css={classes.iconContainer}>
          <Image alt="Coco" src={`${CDN_URL}/images/icons/progress-icon-coco.svg`} layout="fill" />
        </div>

        <div css={classes.captionLabel}>{localeFormatDate(new Date(timeLoaded), 'p')}</div>
      </div>

      <div css={classes.bar}>
        <Image alt="" src={`${CDN_URL}/images/gradient-bar.svg`} layout="fill" />
      </div>

      <div css={classes.barVertical}>
        <Image alt="" src={`${CDN_URL}/images/gradient-bar-vertical-2.svg`} layout="fill" />
      </div>

      <div css={classes.stepContainer}>
        <div css={classes.stateLabel}>{t(DELIVERY_STATUS_KEYS.AT_CUSTOMER, 'At Customer')}</div>
        <div css={classes.iconContainer}>
          <Image alt="Customer" src={`${CDN_URL}/images/icons/progress-icon-home.svg`} layout="fill" />
        </div>

        <div css={classes.captionLabel}>{localeFormatDate(new Date(timeAtCustomer), 'p')}</div>
      </div>

      <div css={classes.bar}>
        <Image alt="" src={`${CDN_URL}/images/gradient-bar.svg`} layout="fill" />
      </div>

      <div css={classes.barVertical}>
        <Image alt="" src={`${CDN_URL}/images/gradient-bar-vertical-2.svg`} layout="fill" />
      </div>

      <div css={classes.stepContainer}>
        <div css={classes.stateLabel}>{t(DELIVERY_STATUS_KEYS.DELIVERED, 'Delivered')}</div>
        <div css={classes.iconContainer}>
          <Image alt="Customer" src={`${CDN_URL}/images/icons/progress-icon-home.svg`} layout="fill" />
        </div>

        <div css={classes.captionLabel}>{localeFormatDate(new Date(timeDelivered), 'p')}</div>
      </div>
    </div>
  );
};

export default ProgressBar;
