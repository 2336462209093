import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Colors } from '@coco/ui-web';

import { LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { trackSegment } from 'src/lib/segment';
import { EVENTS, Mode } from '../@types';

interface Props {
  mode: Mode;
  onChange: (newMode: Mode) => void;
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 30px;
  height: 90px;
`;

interface ButtonProps {
  active?: boolean;
}

const Button = styled.button<ButtonProps>`
  height: 50px;
  min-width: 150px;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: ${(props) => (props.active ? Colors.grey200 : Colors.white)};
  border-radius: 3px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.grey700};
  cursor: pointer;
`;

const ActiveOrdersButton = styled(Button)`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const OrderHistoryButton = styled(Button)`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const ModeSelector = ({ mode, onChange }: Props) => {
  const { t } = useTranslation(LOC_NS.ORDERS);
  const { user } = useAuth0();

  const handleChangeMode = useCallback(
    (newMode: Mode, buttonName: string) => {
      trackSegment(EVENTS.BUTTON_CLICKED, {
        button_name: buttonName,
        auth_id: user?.sub,
        button_clicked_at: Date.now(),
      });

      onChange(newMode);
    },
    [onChange, user?.sub]
  );

  return (
    <Container>
      <ActiveOrdersButton active={mode === Mode.ACTIVE} onClick={() => handleChangeMode(Mode.ACTIVE, 'Active Orders')}>
        {t(ORDERS_KEYS.ACTIVE_ORDERS, 'Active Orders')}
      </ActiveOrdersButton>
      <OrderHistoryButton
        active={mode === Mode.HISTORY}
        onClick={() => handleChangeMode(Mode.HISTORY, 'Order History')}
      >
        {t(ORDERS_KEYS.ORDER_HISTORY, 'Order History')}
      </OrderHistoryButton>
    </Container>
  );
};

export default ModeSelector;
