import styled from '@emotion/styled';

const Button = styled.button`
  background: #141313;
  width: 100%;
  max-width: 500px;
  height: 64px;
  font-size: 18px;
  color: white;
  border-radius: 4px;
  border: 0;
  font-weight: 500;
  cursor: pointer;
  &:active: {
    background-color: #3d3c3c;
  }
`;

export default Button;
