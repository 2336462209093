export const NODE_ENV = process.env.NODE_ENV as string;

// Auth0 Environment values
export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string;
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string;
export const AUTH0_ORIGIN_URL = process.env.NEXT_PUBLIC_ORIGIN_URL as string;
export const AUTH0_AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE as string;

// API services
export const DELIVERIES_BASE_ENDPOINT = process.env.NEXT_PUBLIC_DELIVERIES_BASE_URL as string;
export const OPERATIONS_API_ENDPOINT = process.env.NEXT_PUBLIC_OPERATIONS_API_URL as string;
// Privacy API
export const INTEGRATIONS_EU_API_ENDPOINT = process.env.NEXT_PUBLIC_INTEGRATIONS_EU_API_URL as string;

// Mapbox
export const MAPBOX_ACCESS_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN as string;

// Analytics
export const FULLSTORY_ORG_ID = process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID as string;
export const NEXT_PUBLIC_SEGMENT_MERCHANT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_MERCHANT_WRITE_KEY as string;

// LaunchDarkly (feature flagging)
export const LAUNCHDARKLY_SDK_CLIENT_SIDE_ID = process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID as string;

// Pusher
export const PUSHER_APP_KEY = process.env.NEXT_PUBLIC_PUSHER_APP_KEY as string;
export const PUSHER_CLUSTER = process.env.NEXT_PUBLIC_PUSHER_CLUSTER as string;

// Versioning
export const NEXT_PUBLIC_AWS_ENV = process.env.NEXT_PUBLIC_AWS_ENV as string;
export const NEXT_PUBLIC_APP_VERSION = (process.env.NEXT_PUBLIC_APP_VERSION as string) || 'unknown';
