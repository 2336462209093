import styled from '@emotion/styled';
import { ReactNode } from 'react';

import { Colors } from '@coco/ui-web';

interface Props {
  iconUrl?: string;
  children: ReactNode;
}

const PillContainer = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid ${Colors.grey200};
  border-radius: 20px;
  background-color: white;
`;

const Icon = styled.img`
  height: 20px;
  margin-right: 5px;
`;

const CopyContainer = styled.div`
  font-weight: 500;
  font-size: 15px;
  padding-top: 4px;
`;

const Pill = ({ iconUrl, children }: Props) => {
  return (
    <PillContainer>
      {iconUrl && <Icon src={iconUrl} />}
      <CopyContainer>{children}</CopyContainer>
    </PillContainer>
  );
};

export default Pill;
