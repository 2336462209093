import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListDelivery } from 'src/@types';
import { LOC_NS } from 'src/i18n/types';
import { getRobotEtaCopy } from 'src/lib/deliveries';
import { THIRTY_SECONDS_IN_MS } from 'src/lib/time';

const useRobotEtaCopy = (estPickupTime: ListDelivery['estPickupTime'], enabled: boolean) => {
  const { i18n } = useTranslation(LOC_NS.ORDERS);
  const [copy, setCopy] = useState('');

  useEffect(() => {
    const updateRobotEta = () => {
      if (!enabled) {
        setCopy('');
        return;
      }
      setCopy(getRobotEtaCopy(estPickupTime) ?? '');
    };
    updateRobotEta();

    // we use setInterval because we shouldn't rely on the attempt object changing to trigger copy updates
    const interval = setInterval(updateRobotEta, THIRTY_SECONDS_IN_MS);
    return () => clearInterval(interval);
  }, [enabled, estPickupTime, i18n.resolvedLanguage]);

  return copy;
};

export default useRobotEtaCopy;
