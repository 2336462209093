import styled from '@emotion/styled';
import { BarLoader } from 'react-spinners';

const Container = styled.div`
  margin-top: 50px;
  padding: 0px 30px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Loader = () => {
  return (
    <Container>
      <LoaderContainer>
        <BarLoader color="#141313" />
      </LoaderContainer>
    </Container>
  );
};

export default Loader;
