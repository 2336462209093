import { useEffect, useState } from 'react';

import { CDN_URL } from '@coco/ui-web';

const TIMEOUT_DURATION_MS = 5 * 60 * 1000; // 5 minutes in ms

const WarningAudio = () => {
  /* Handle server-side situation where Audio object does not exist */
  const [audio] = useState(
    typeof Audio !== 'undefined' ? new Audio(`${CDN_URL}/audio/load-warning.mp3`) : ({} as typeof Audio)
  );

  useEffect(() => {
    // chrome and other browsers block auto-playing sounds until a user has interacted with the page
    // therefore, sound may be unreliable
    let timer: NodeJS.Timeout;
    const run = () => {
      audio?.play();

      timer = setTimeout(run, TIMEOUT_DURATION_MS);
    };

    run();

    return () => clearTimeout(timer);
  }, [audio]);

  return null;
};

export default WarningAudio;
