import { AxiosInstance } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { ListDelivery, TrackerDeliveryInfo } from '../@types';
import { AppContext } from '../components';

interface Params {
  deliveryId: ListDelivery['id'];
  config?: UseQueryOptions;
}

const fetchDeliveryInfo = async (deliveriesApi: AxiosInstance, deliveryId: string): Promise<TrackerDeliveryInfo> => {
  const { data } = await deliveriesApi.get(`/api/v2/delivery/${deliveryId}/tracker-info`);

  return data;
};

const useDeliveryInfo = ({ deliveryId, config }: Params) => {
  const { deliveriesApi } = AppContext.useAppContext();

  return useQuery({
    queryKey: ['delivery-info', deliveryId],
    queryFn: () => fetchDeliveryInfo(deliveriesApi, deliveryId),
    ...config,
  });
};

export default useDeliveryInfo;
