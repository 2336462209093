import { useAuth0 } from '@auth0/auth0-react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBattery } from 'react-use';

import { CDN_URL, Colors, Typography } from '@coco/ui-web';

import { EVENTS } from 'src/@types';
import { LOC_NS, WARNINGS_KEYS } from 'src/i18n/types';
import { trackSegment } from 'src/lib/segment';
import { FIVE_MINUTES_IN_MS } from 'src/lib/time';

const pulse = keyframes({
  '0%': {
    opacity: '0.5',
  },
  '50%': {
    opacity: '1',
  },
  '100%': {
    opacity: '0.5',
  },
});

const Container = styled('div')<{ canShown: boolean }>`
  height: ${(props) => (props.canShown ? '50px' : '0px')};
`;

const LowBattery = styled.div({
  height: '100%',
  backgroundColor: Colors.red500,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  animation: `${pulse} 2s infinite`,
  color: Colors.white,
  textAlign: 'center',
});

// the default type from react-use doesn't work
interface UseBatteryState {
  isSupported: boolean;
  fetched: boolean;
  charging: boolean;
  level: number;
}

export const BatteryLevel = () => {
  const { t } = useTranslation(LOC_NS.WARNINGS);
  const { merchantPortalLoadReminders } = useFlags();
  const { user } = useAuth0();

  const { isSupported, fetched, level, charging } = useBattery() as UseBatteryState;

  const [audio] = useState(
    typeof Audio !== 'undefined' ? new Audio(`${CDN_URL}/audio/reminder.mp3`) : ({} as typeof Audio)
  );

  const batteryMinLevel = useMemo(() => {
    if (merchantPortalLoadReminders?.batteryMinLevel) {
      return merchantPortalLoadReminders.batteryMinLevel;
    }
    return 0.2; // default to 20%
  }, [merchantPortalLoadReminders?.batteryMinLevel]);

  const showAlert = useMemo(
    () => isSupported && fetched && !charging && level <= batteryMinLevel,
    [batteryMinLevel, charging, fetched, isSupported, level]
  );

  useEffect(() => {
    if (showAlert && user?.sub && merchantPortalLoadReminders?.batteryMinLevel) {
      trackSegment(EVENTS.LOW_BATTERY_ALERT, {
        alert_appeared_at: Date.now(),
        battery_threshold: merchantPortalLoadReminders.batteryMinLevel,
        auth_id: user.sub,
      });
    } else if (!showAlert && user?.sub && merchantPortalLoadReminders?.batteryMinLevel) {
      trackSegment(EVENTS.LOW_BATTERY_ALERT_DISMISSED, {
        alert_dismissed_at: Date.now(),
        battery_threshold: merchantPortalLoadReminders.batteryMinLevel,
        auth_id: user.sub,
      });
    }
  }, [merchantPortalLoadReminders?.batteryMinLevel, showAlert, user?.sub]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const run = () => {
      audio?.play();
      timer = setTimeout(run, FIVE_MINUTES_IN_MS);
    };

    if (showAlert) {
      run();
    }

    return () => clearTimeout(timer);
  }, [audio, showAlert]);

  return (
    <Container canShown={showAlert}>
      {showAlert && (
        <LowBattery className={Typography.BODY_LG}>
          {t(WARNINGS_KEYS.LOW_BATTERY, 'Low battery. Connect to charger.')}
        </LowBattery>
      )}
    </Container>
  );
};
