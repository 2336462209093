export enum Typography {
  DISPLAY_LG = 'display-lg',
  DISPLAY_MD = 'display-md',
  DISPLAY_SM = 'display-sm',
  DISPLAY_XS_MED = 'display-xs-medium',
  HEADLINE_LG = 'headline-lg',
  HEADLINE_MD = 'headline-md',
  HEADLINE_SM = 'headline-sm',
  TITLE_LG = 'title-lg',
  TITLE_MD = 'title-md',
  TITLE_SM = 'title-sm',
  LABEL_XL = 'label-xl',
  LABEL_LG = 'label-lg',
  LABEL_MD = 'label-md',
  LABEL_SM = 'label-sm',
  BODY_LG = 'body-lg',
  BODY_MD = 'body-md',
  BODY_SM = 'body-sm',
}
