import { useAuth0 } from '@auth0/auth0-react';
import Pusher, { Channel } from 'pusher-js';
import { useEffect, useMemo, useState } from 'react';

import { PUSHER_APP_KEY, PUSHER_CLUSTER } from 'src/lib/env';

const pusher = new Pusher(PUSHER_APP_KEY, {
  cluster: PUSHER_CLUSTER,
  enableStats: true,
});

// Pusher.logToConsole = true;

export const usePusher = () => {
  const { user } = useAuth0();

  const [merchantChannel, setMerchantChannel] = useState<Channel | null>(null);

  const merchantChannelName = useMemo(() => {
    if (!user?.sub) {
      return '';
    }

    // channel name cannot contain |, so strip out the auth0| prefix from user id
    return user.sub.replace('auth0|', '');
  }, [user?.sub]);

  useEffect(() => {
    if (!merchantChannelName) {
      return;
    }

    // TODO - analytics
    pusher.connection.bind('error', (error: Error) => {
      console.error('pusher error', error);
    });

    if (!pusher.allChannels().find((channel) => channel.name === merchantChannelName)) {
      // if subscription doesn't exist, create it
      console.log(`created merchantid channel - ${merchantChannelName}`);
      setMerchantChannel(pusher.subscribe(merchantChannelName));
    }
  }, [merchantChannelName]);

  return {
    merchantChannel,
  };
};
