import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { ReactNode, useEffect, useState } from 'react';

import { UserRole } from '../@types';
import FullPageLoader from './FullPageLoader';
import Unauthorized from './Unauthorized';

const ROLE_GROUP_KEY = 'https://cocodelivery.com/schemas/identity/claims/roles';

interface Props {
  children: ReactNode;
}

const AccessProvider = ({ children }: Props) => {
  const { isLoading, isAuthenticated, user } = useAuth0();
  const [appReady, setAppReady] = useState<boolean>(false);
  const [showUnauthorized, setShowUnauthorized] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated && user) {
      const roles = user[ROLE_GROUP_KEY];
      if (roles.includes(UserRole.Merchants)) {
        setAppReady(true);
      } else {
        setAppReady(true);
        setShowUnauthorized(true);
      }
    }
  }, [isAuthenticated, showUnauthorized, user]);

  if (isLoading || !appReady) {
    return <FullPageLoader />;
  }

  if (showUnauthorized) {
    return <Unauthorized />;
  }

  return <>{children}</>;
};

export default withAuthenticationRequired(AccessProvider);
