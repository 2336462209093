import { AxiosInstance } from 'axios';
import { useInfiniteQuery } from 'react-query';

import { populateDeliveriesWithPII } from 'src/lib/deliveries';
import { DeliveryOperationalMode, ListDelivery } from '../@types';
import { AppContext } from '../components';

export const PAGE_LIMIT = 10;

interface QueryV2Response {
  data: {
    deliveries: ListDelivery[];
  };
  total: number;
  limit: number;
  offset: number;
}

interface QueryFnParams {
  deliveriesApi: AxiosInstance;
  privacyApi: AxiosInstance;
  partnerIds: string[];
  pageParam: number;
}

const getDeliveriesForPartner = async ({
  deliveriesApi,
  privacyApi,
  partnerIds,
  pageParam,
}: QueryFnParams): Promise<QueryV2Response> => {
  const { data: queryRes } = await deliveriesApi.get(`/api/v2/delivery/query-v2`, {
    params: {
      partnerIds,
      includeLocation: true,
      operationalModes: [DeliveryOperationalMode.Canceled, DeliveryOperationalMode.Completed],
      isActivePartner: true,
      limit: PAGE_LIMIT,
      offset: pageParam ? pageParam : 0,
    },
  });

  const { data, ...restQueryData } = queryRes;
  const deliveriesWithPII = await populateDeliveriesWithPII(data.deliveries, privacyApi);
  return { ...restQueryData, data: { ...data, deliveries: deliveriesWithPII } };
};

interface UseDeliveriesHistoryParams {
  params: {
    partnerIds: string[];
  };
  enabled: boolean;
}

const useDeliveriesHistory = ({ params, enabled }: UseDeliveriesHistoryParams) => {
  const { deliveriesApi, privacyApi } = AppContext.useAppContext();

  return useInfiniteQuery({
    queryKey: 'merchant-deliveries-history',
    queryFn: ({ pageParam }) =>
      getDeliveriesForPartner({ deliveriesApi, privacyApi, partnerIds: params.partnerIds, pageParam }),
    enabled,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => {
      const nextOffset = lastPage.offset + PAGE_LIMIT;
      return nextOffset; // injects next offset param into queryFn's pageParam
    },
  });
};

export default useDeliveriesHistory;
