export interface ParkingLotCurrentlyAssignedTo {
  id: string;
  name: string;
}

export interface Robot {
  serial: string;
  createdAt?: Date;
  parkingLotCurrentlyAssignedTo?: ParkingLotCurrentlyAssignedTo;
}

export enum RobotAvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  BUSY = 'BUSY',
}

export enum RobotState {
  ON_TRIP = 'ON_TRIP',
  GROUNDED = 'GROUNDED',
  PARKED = 'PARKED',
  OFF_DUTY = 'OFF_DUTY',
}

export interface ParkingLotRobot {
  availabilityStatus: RobotAvailabilityStatus;
  robotState: { robotState: RobotState };
}
