import Bot from './Bot.png';
import Bot2 from './Bot2.png';
import Coco1Img from './coco1.png';
import CourierImg from './courier.png';
import CourierIconComplete from './CourierComplete.png';
import Customer from './Customer.png';
import DeliveryRescueImg from './DeliveryRescue.png';
import DestinationIconComplete from './DestinationComplete.png';
import DoorDashImg from './doordash.png';
import Driver from './Driver.png';
import GreenBot from './GreenBot.png';
import Heading from './Heading.png';
import Merchant from './Merchant.png';
import MerchantIconComplete from './MerchantComplete.png';
import One from './One.png';
import PilotImg from './pilot.png';
import RedBot from './RedBot.png';
import RobotIconComplete from './RobotComplete.png';
import RobotSimple from './RobotSimple.png';
import Two from './Two.png';
import YellowBot from './YellowBot.png';

export {
  Bot,
  Bot2,
  Coco1Img,
  CourierIconComplete,
  CourierImg,
  Customer,
  DeliveryRescueImg,
  DestinationIconComplete,
  DoorDashImg,
  Driver,
  GreenBot,
  Heading,
  Merchant,
  MerchantIconComplete,
  One,
  PilotImg,
  RedBot,
  RobotIconComplete,
  RobotSimple,
  Two,
  YellowBot,
};
