import { format as formatDate } from 'date-fns';
import { enUS, fi, sv } from 'date-fns/locale';
import i18n from 'i18next';

import { Provider, Source } from '@coco/types/deliveries';
import { CDN_URL } from '@coco/ui-web';

import { ListDelivery, Locale } from '../@types';

const SOURCE_MAP = <Record<Source, string>>{
  [Source.ally]: 'Ally',
  [Source.chow_now]: 'Chow Now',
  [Source.doordash]: 'DoorDash',
  [Source.door_dash]: 'DoorDash',
  [Source.door_dash_marketplace]: 'DoorDash Marketplace',
  [Source.grubhub]: 'Grubhub',
  [Source.olo]: 'Olo',
  [Source.otter]: 'Otter',
  [Source.postmates]: 'Postmates',
  [Source.ritual]: 'Ritual',
  [Source.toast]: 'Toast',
  [Source.uber_eats]: 'Uber Eats',
  [Source.uber_eats_marketplace]: 'Uber Eats Marketplace',
  [Source.coco_mobile_app]: 'Coco',
};

const DELIVERY_SOURCE_ASSETS = <Record<Source, string>>{
  [Source.ally]: `${CDN_URL}/images/ally.png`,
  [Source.chow_now]: `${CDN_URL}/images/chownow.png`,
  [Source.doordash]: `${CDN_URL}/images/doordash-v2.png`,
  [Source.door_dash]: `${CDN_URL}/images/doordash-v2.png`,
  [Source.door_dash_marketplace]: `${CDN_URL}/images/doordash-v2.png`,
  [Source.grubhub]: `${CDN_URL}/images/grubhub.png`,
  [Source.olo]: `${CDN_URL}/images/olo.png`,
  [Source.otter]: `${CDN_URL}/images/otter.png`,
  [Source.ritual]: `${CDN_URL}/images/ritual.png`,
  [Source.toast]: `${CDN_URL}/images/toast.png`,
  [Source.uber_eats]: `${CDN_URL}/images/uber-eats.png`,
  [Source.uber_eats_marketplace]: `${CDN_URL}/images/uber-eats.png`,
  [Source.coco_mobile_app]: `${CDN_URL}/images/coco.png`,
};

export const getOrderSourceNameFromSource = (orderSource: Source | null) => {
  if (!orderSource) {
    return '';
  }
  switch (orderSource) {
    case Source.door_dash:
    case Source.door_dash_marketplace:
      return 'DD';
    case Source.grubhub:
      return 'GH';
    case Source.uber_eats:
    case Source.uber_eats_marketplace:
      return 'UE';
    case Source.chow_now:
      return 'CN';
    case Source.postmates:
      return 'PM';
    default:
      return SOURCE_MAP[orderSource];
  }
};

export const getOrderSourceImageUrl = (orderSource: Source | null) => {
  if (orderSource && DELIVERY_SOURCE_ASSETS[orderSource]) {
    return DELIVERY_SOURCE_ASSETS[orderSource];
  }
  return '';
};

export const getProviderImageUrl = (provider: Provider) => {
  if (provider === Provider.Robot) {
    return `${CDN_URL}/images/icons/coco-pink-v2.png`;
  }

  if (provider === Provider.Uber) {
    return `${CDN_URL}/images/icons/fo-pink.png`;
  }

  if (provider === Provider.DoorDash) {
    return `${CDN_URL}/images/icons/courier-black-v2.png`;
  }
};

/*
  await delayAsync(1000) would pause execution by 1 second
*/
export const delayAsync = async (delayMs: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), delayMs);
  });
};

export const coalescePages = (pages: Array<ListDelivery[]> | undefined): ListDelivery[] => {
  if (!pages) {
    return [];
  }

  let acc: ListDelivery[] = [];

  pages.forEach((page: ListDelivery[]) => {
    acc = [...acc, ...page];
  });

  // Remove duplicates in the array which can occur during hot reloading
  const filteredAcc = acc.filter((delivery, idx, curArray) => {
    return curArray.findIndex((ele) => ele.id === delivery.id) === idx;
  });

  return filteredAcc;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasBotSerial = (x: Record<string, any>): x is { botSerial: string } => {
  return x.botSerial !== undefined;
};

const LocaleMap = {
  [Locale.EN]: enUS,
  [Locale.FI]: fi,
  [Locale.SV]: sv,
};

export const getDateFnsLocale = () => {
  return LocaleMap[i18n.language as Locale] ?? enUS;
};

export const localeFormatDate = (date: Date | number, format: string) => {
  const locale = getDateFnsLocale();

  return formatDate(new Date(date), format, { locale });
};
