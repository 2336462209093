import styled from '@emotion/styled';
import { BarLoader } from 'react-spinners';

const FullPageContainer = styled.div`
  height: calc(100vh - 82px - 100px); // 82px is height of header and 100px is height of mode selector
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullPageLoader = () => {
  return (
    <FullPageContainer>
      <BarLoader color="#141313" />
    </FullPageContainer>
  );
};

export default FullPageLoader;
