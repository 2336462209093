import { t } from 'i18next';

import { Item } from '@coco/types/deliveries';

import { LOC_NS, ORDERS_KEYS } from 'src/i18n/types';

export const formatCartItemTitle = (cartItem: Item): string => {
  /* handle edge case where 3pl parsed orders have their titles placed in `description` property instead */
  if (cartItem?.description && cartItem.title === '3pl-item') {
    return cartItem.description.replace(/\r?\n|\r/g, ''); // remove any newline characters from parsed string
  }
  return cartItem?.title ?? t(`${LOC_NS.ORDERS}:${ORDERS_KEYS.ITEM}`, 'Item');
};
