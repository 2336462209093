import { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';

import { DeliveryCancelReason } from '@coco/types/deliveries';

import { AppContext } from 'src/components';

interface OptOutParams {
  deliveryId: string;
  reason: DeliveryCancelReason;
}

const cancelDelivery = async (deliveriesApi: AxiosInstance, deliveryId: string, reason: DeliveryCancelReason) => {
  return deliveriesApi.patch(`/api/v3/delivery/${deliveryId}/cancel`, {
    reason,
    force: true,
  });
};

export const useCancelDelivery = ({ deliveryId, reason }: OptOutParams) => {
  const { deliveriesApi } = AppContext.useAppContext();

  return useMutation({
    mutationFn: () => cancelDelivery(deliveriesApi, deliveryId, reason),
  });
};
