import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AttemptStatus, Source } from '@coco/types/deliveries';
import { CocoButtonV2, Colors } from '@coco/ui-web';

import { CANCEL_DELIVERY_KEYS, LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { cancelableAttemptStatuses } from 'src/lib/deliveries';
import { trackSegment } from 'src/lib/segment';
import { useDeliveryStore } from 'src/stores/delivery';
import { AttemptProvider, DeliveryOperationalMode, EVENTS, ListDelivery, Trip, TripType } from '../../@types';
import DeliveryReceipt from './DeliveryReceipt';
import ProgressBar from './ProgressBar';

interface Props {
  isActiveOrdersTab: boolean;
  currentTrip: Trip | undefined;
  listDelivery: ListDelivery;
}

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-self: flex-end;
`;

const NoInformation = styled.div`
  margin-top: 8px;
  font-size: 16px;
  text-align: center;
  color: ${Colors.grey300};
`;

const DeliveryDetails = ({ listDelivery, isActiveOrdersTab, currentTrip }: Props) => {
  const { t: cancelDeliveryT } = useTranslation(LOC_NS.CANCEL_DELIVERY);
  const { t: ordersT } = useTranslation(LOC_NS.ORDERS);
  const { user } = useAuth0();

  const { statusTimestampsMap, createdAt, providedBy } = listDelivery;

  const { setSelectedDelivery } = useDeliveryStore();

  const orderCanceled = useMemo(
    () =>
      listDelivery?.operationalMode === DeliveryOperationalMode.Canceled ||
      listDelivery?.currentAttemptStatus === AttemptStatus.Canceled,
    [listDelivery?.currentAttemptStatus, listDelivery?.operationalMode]
  );

  const handleOpenCancelModal = useCallback(
    (listDelivery: ListDelivery) => {
      // When the merchant clicks on Cancel Order button (open modal)
      trackSegment(EVENTS.BUTTON_CLICKED, {
        button_name: 'Cancel Order',
        order_id: listDelivery?.id,
        attempt_status: listDelivery?.currentAttemptStatus,
        auth_id: user?.sub,
        button_clicked_at: Date.now(),
      });

      setSelectedDelivery(listDelivery);
    },
    [setSelectedDelivery, user?.sub]
  );

  const orderisCancelable = useMemo(() => {
    if (
      isActiveOrdersTab &&
      listDelivery?.currentAttemptStatus &&
      listDelivery.orderSource !== Source.door_dash_marketplace &&
      cancelableAttemptStatuses.includes(listDelivery.currentAttemptStatus)
    ) {
      return true;
    }

    return false;
  }, [isActiveOrdersTab, listDelivery.currentAttemptStatus, listDelivery.orderSource]);

  const isPreload = useMemo(
    () =>
      currentTrip?.type === TripType.DELIVERY &&
      !!listDelivery.currentAttemptStatus &&
      ([AttemptStatus.Pending, AttemptStatus.AtPickup] as AttemptStatus[]).includes(listDelivery.currentAttemptStatus),
    [currentTrip?.type, listDelivery.currentAttemptStatus]
  );

  const mainContent = useMemo(() => {
    if (isPreload || !listDelivery.currentAttemptStatus || orderisCancelable || orderCanceled) {
      return <DeliveryReceipt deliveryId={listDelivery.id} />;
    }
    return null;
  }, [isPreload, listDelivery.currentAttemptStatus, listDelivery.id, orderCanceled, orderisCancelable]);

  const showCancelOrderButton = useMemo(() => orderisCancelable && !orderCanceled, [orderCanceled, orderisCancelable]);

  const progressBarContent = useMemo(() => {
    if (
      !isActiveOrdersTab &&
      createdAt &&
      (statusTimestampsMap?.LoadedWaitingForPilot || statusTimestampsMap?.InTransit) &&
      statusTimestampsMap?.AtDestination &&
      statusTimestampsMap?.Delivered
    ) {
      const timeLoaded = statusTimestampsMap.LoadedWaitingForPilot ?? statusTimestampsMap.InTransit ?? '';
      return (
        <ProgressBar
          timeRequested={createdAt}
          timeLoaded={timeLoaded}
          timeAtCustomer={statusTimestampsMap.AtDestination}
          timeDelivered={statusTimestampsMap.Delivered}
          isRobotOrder={providedBy === AttemptProvider.ROBOT}
        />
      );
    }
    return null;
  }, [createdAt, isActiveOrdersTab, providedBy, statusTimestampsMap]);

  return (
    <Container>
      {progressBarContent}
      <Row>
        {showCancelOrderButton && (
          <CocoButtonV2
            size="large"
            buttonType="outlined"
            status={orderCanceled ? 'disabled' : 'enabled'}
            hideShadows
            onClick={() => handleOpenCancelModal(listDelivery)}
          >
            {cancelDeliveryT(CANCEL_DELIVERY_KEYS.CANCEL_DELIVERY, 'Cancel Delivery')}
          </CocoButtonV2>
        )}
      </Row>

      {!!mainContent ? (
        mainContent
      ) : !progressBarContent ? (
        <NoInformation>{ordersT(ORDERS_KEYS.NO_DETAILS, 'No Details')}</NoInformation>
      ) : null}
    </Container>
  );
};

export default DeliveryDetails;
