export enum EVENTS {
  PAGE_VIEWED = 'Page Viewed',
  BUTTON_CLICKED = 'Button Clicked',
  LOW_BATTERY_ALERT = 'Low Battery Alert',
  LOW_BATTERY_ALERT_DISMISSED = 'Low Battery Alert Dismissed',
  LANGUAGE_CHANGE = 'Language Change',
  DISPLAY_ROBOT_ETA = 'Display Robot ETA',
}

export enum ERROR {
  QUERY_FN_ERROR = 'Error encountered in query function',
  MUTATION_FN_ERROR = 'Error encountered in mutation function',
  MISSING_ESTIMATED_PICKUP_TIME = 'Missing estimated pickup time',
  PAST_ESTIMATED_PICKUP_TIME = 'Past estimated pickup time',
  ESTIMATED_PICKUP_OVER_30_MINUTES = 'Estimated pickup time over 30 minutes',
}
