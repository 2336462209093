import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BarLoader } from 'react-spinners';

import { Delivery } from '@coco/types/deliveries';
import { Colors } from '@coco/ui-web';

import useDeliveryV3 from 'src/hooks/useDeliveryV3';
import { LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { formatCartItemTitle } from '../../lib/cart';
import { formatCentsToDollars } from '../../lib/currency';

interface Props {
  deliveryId: Delivery['id'];
}

const CartRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ItemDescription = styled.div`
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 500;
`;

const ItemPrice = styled.div`
  font-size: 15px;
`;

const SummaryContainer = styled.div`
  width: 50%;
  margin-left: auto;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const SummaryCaption = styled.div`
  font-weight: 500;
  font-size: 15px;
  width: 80px;
  margin-right: 10px;
`;

const SummaryValue = styled.div`
  font-size: 15px;
`;

const SummaryCaptionTotal = styled(SummaryCaption)`
  font-weight: bold;
`;

const SummaryValueTotal = styled(SummaryValue)`
  font-weight: bold;
`;

const ItemQuantityAndDescription = styled.div`
  display: flex;
`;

const ItemQuantity = styled(ItemDescription)`
  margin-right: 10px;
  width: 40px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const DeliveryInfoError = styled.div`
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin-top: 50px;
`;

const NoInformation = styled.div`
  margin-top: 8px;
  font-size: 16px;
  text-align: center;
  color: ${Colors.grey300};
`;

const DeliveryReceipt = ({ deliveryId }: Props) => {
  const { t } = useTranslation(LOC_NS.ORDERS);
  const { data: delivery, isLoading, error } = useDeliveryV3({ deliveryId });
  const noReceiptData = useMemo(() => {
    if (!delivery) return true;
    const { subtotal, tax, tip, total } = delivery ?? {};
    if (!subtotal && !tax && !tip && !total) return true;
    return false;
  }, [delivery]);

  if (isLoading) {
    return (
      <LoaderContainer>
        <BarLoader color="#141313" />
      </LoaderContainer>
    );
  }

  if (error) {
    return (
      <DeliveryInfoError>
        {t(ORDERS_KEYS.UNABLE_TO_FETCH_ORDER_DETAILS, 'Unable to fetch order details')}
      </DeliveryInfoError>
    );
  }

  if (!delivery || noReceiptData) {
    return <NoInformation>{t(ORDERS_KEYS.NO_DETAILS, 'No Details')}</NoInformation>;
  }

  return (
    <div>
      <Divider sx={{ margin: '10px 0px 20px' }} />
      {delivery?.items?.length > 0 && (
        <>
          {delivery.items.map((item) => {
            return (
              <CartRow key={item.title}>
                <ItemQuantityAndDescription>
                  <ItemQuantity>{item.quantity}x</ItemQuantity>
                  <ItemDescription>{formatCartItemTitle(item)}</ItemDescription>
                </ItemQuantityAndDescription>

                <ItemPrice>{formatCentsToDollars(item.price)}</ItemPrice>
              </CartRow>
            );
          })}
          <Divider sx={{ margin: '0px 0px 20px' }} />
        </>
      )}
      <SummaryContainer>
        <SummaryRow>
          <SummaryCaption>{t(ORDERS_KEYS.SUBTOTAL, 'Subtotal')}</SummaryCaption>
          <SummaryValue>{formatCentsToDollars(delivery?.subtotal) ?? 'N/A'}</SummaryValue>
        </SummaryRow>
        <SummaryRow>
          <SummaryCaption>{t(ORDERS_KEYS.TAX, 'Tax')}</SummaryCaption>
          <SummaryValue>{formatCentsToDollars(delivery?.tax) ?? 'N/A'}</SummaryValue>
        </SummaryRow>
        <SummaryRow>
          <SummaryCaption>{t(ORDERS_KEYS.TIP, 'Tip')}</SummaryCaption>
          <SummaryValue>{formatCentsToDollars(delivery?.tip) ?? 'N/A'}</SummaryValue>
        </SummaryRow>
        <SummaryRow>
          <SummaryCaptionTotal>{t(ORDERS_KEYS.TOTAL, 'Total')}</SummaryCaptionTotal>
          <SummaryValueTotal>{formatCentsToDollars(delivery?.total) ?? 'N/A'}</SummaryValueTotal>
        </SummaryRow>
      </SummaryContainer>
    </div>
  );
};

export default DeliveryReceipt;
